import { useState, useContext, useEffect, useMemo } from "react";

import { Spinner } from "react-bootstrap";
import { InputNumber, Modal, Tooltip, Button } from "antd";
import axios from "axios";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { useUserState } from "../Contexts/UserContext";
import { HouseContext } from "../Contexts/HouseContext";

import UploadImg from "../assets/images/upload.png";
import OwnerPlusSvg from "../assets/images/ownerplus.svg";
import CloseCircleSvg from "../assets/images/close-circle.svg";

const amenitiesData = [
  "Air conditioner",
  "Internet",
  "Elevator",
  "Stairs",
  "Power backup",
  "Security",
  // "Park",
  "Fire safety",
  "Parking",
  // "Pet Friendly",
];

const utilitiesData = [
  "Electricity",
  "Gas",
  "Water",
  "Heater",
  "Internet",
  "Sewer",
  "Trash",
];

const restrictedWordsRegex =
  /\b(?:one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty|thirty|forty|fifty|sixty|seventy|eighty|ninety|hundred|thousand|million|billion|road|rd|drive|dr|street|st|str|near|avenue|ave|boulevard|blvd|lane|ln|court|ct|way|wy|place|pl|circle|cir|square|sq|terrace|ter|parkway|pkway|alley|aly|path|pth|close|cl|crescent|cres|trail|trl|highway|hwy|expressway|expy|freeway|fwy|loop|row|r\s?-?\s?o\s?-?\s?a\s?-?\s?d)\b|\b\d+\b/gi;
const restrictedWordsPatternForHouseDescription =
  /\b(tel|call|road|rd|drive|dr|street|st|str|near|avenue|ave|boulevard|blvd|lane|ln|court|ct|way|wy|place|pl|circle|cir|square|sq|terrace|ter|parkway|pkway|alley|aly|path|pth|close|cl|crescent|cres|trail|trl|highway|hwy|expressway|expy|freeway|fwy|loop|row)\b/gi;
const phonePattern =
  /(\+?\d{1,2}\s?)?(\(\d{3}\)|\d{3})[\s\-\.]?\d{3}[\s\-\.]?\d{4}/g;
const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
const urlPattern = /\b(?:https?|ftp):\/\/\S+\b|\bwww\.\S+/gi;

function OwnerAddHouse({ toggleComponentHouse }) {
  const { user } = useUserState();
  const [iSubmitConfirmation, setIsSubmitConfirmation] = useState(false);

  const { house, setHouse } = useContext(HouseContext);
  const [inputData, setInputData] = useState({
    propertyName: house?.name ? house?.name : "",
    country: house?.country ? house?.country : "US",
    state: house?.state ? house?.state : "",
    zipCode: house?.zip_code ? house?.zip_code : "",
    monthlyPrice: house?.monthly_price ? parseInt(house?.monthly_price) : "",
    securityPrice: house?.security_prices
      ? parseInt(house?.security_prices)
      : "",
    houseType: house?.house_type ? house?.house_type : "Apartment",
    floorCount: house?.floor ? parseInt(house?.floor) : "",
    bedroomCount: house?.bedroom_no ? parseInt(house?.bedroom_no) : "",
    bathroomCount: house?.bathroom_no ? parseFloat(house?.bathroom_no) : "",
    propertyOption: house?.house_facility
      ? house?.house_facility
      : "Fully Furnished",
    propertyAreaCount: house?.area ? parseInt(house?.area) : "",
    leasePeriodCount: house?.min_lease_period
      ? parseInt(house?.min_lease_period)
      : 1,
    fullAddress: house?.full_address ? house?.full_address.split(";")[0] : "",
    aprt_suite: house?.full_address ? house?.full_address.split(";")[1] : "",
    city: house?.full_address ? house?.full_address.split(";")[2] : "",
    propertyDetail: house?.property_details ? house?.property_details : "",

    propertyUtilityPrice: house?.total_utilities_price,

    isPetAllow: house?.is_pets_allowed,
    utilitiesPriceIncluded: house?.utilities_price_included,
  });

  const [utilityPrice, setUtilityPrice] = useState(
    inputData?.propertyUtilityPrice
  );
  const [inputError, setInputError] = useState(false);
  const [error, setError] = useState(false);
  const [houseImages, setHouseImages] = useState([]);
  const [houseDocImages, setHouseDocImages] = useState([]);
  const [houseAmenities, setHouseAmenities] = useState(
    house?.amenities ? house?.amenities?.split(",") : []
  );
  const [houseUtility, setHouseUtility] = useState([]);

  const [petAllowed, setPetAllowed] = useState(inputData?.isPetAllow || false);

  const [utilityData, setUtilityData] = useState([]);

  const [isUtilityIncludedPrice, isSetUtilityIncludePrice] = useState(
    inputData?.utilitiesPriceIncluded
      ? inputData?.utilitiesPriceIncluded
      : false
  );

  const [btnLoader, setBtnLoader] = useState(false);

  const zipRegex = /^\d{5}(-\d{4})?$/;

  const headersFormData = {
    "Content-Type": "multipart/form-data",
  };

  const headerJson = {
    "Content-Type": "application/json",
    Authorization: user?.token,
  };

  const containsRestrictedWords = (text) => {
    return restrictedWordsRegex.test(text); // Returns true if any restricted word is found
  };
  const containsRestrictedWordForHouseDescription = (text) => {
    return restrictedWordsPatternForHouseDescription.test(text);
  };

  const makeValidHouseDescription = (description) => {
    // Remove restricted words
    description = description
      .replace(restrictedWordsPatternForHouseDescription, "")
      .trim();
    // Remove phone numbers
    // description = description.replace(phonePattern, '').trim();
    // Remove email addresses
    description = description.replace(emailPattern, "").trim();
    description = description.replace(urlPattern, "").trim();
    // Remove numbers
    description = description.replace(/\d+/g, "").trim();
    // Remove special characters except ',' and '-' "."
    description = description.replace(/[^a-zA-Z\s,-.]/g, "").trim();
    // Remove email domains and extensions
    description = description.replace(/\.\w{2,}(\.\w{2,})?/gi, "").trim();
    // Remove email text in any format
    description = description
      .replace(/(?:yahoo|gmail|hotmail|outlook|icloud|aol)/gi, "")
      .trim();
    // Replace multiple spaces with a single space
    description = description.replace(/\s+/g, " ").trim();
    return description;
  };

  const makeValidHousename = (propertyName) => {
    return propertyName
      .replace(restrictedWordsRegex, "") // Remove restricted words and numbers
      .replace(emailPattern, "")
      .trim() // Remove email addresses
      .replace(urlPattern, "")
      .trim()
      .replace(/[^\w\s]/g, "") // Remove special characters
      .replace(/(?:yahoo|gmail|hotmail|outlook|icloud|aol|gmail)/gi, "")
      .trim()
      .replace(/\s+/g, " ") // Replace multiple spaces with a single space
      .trim(); // Trim leading and trailing spaces
  };

  const handleConfirmForSubmit = () => {
    const propertyName = inputData?.propertyName;

    const isvalidHouseName = containsRestrictedWords(propertyName);
    const isValidHouseDescription = containsRestrictedWordForHouseDescription(
      inputData?.propertyDetail
    );
    if (isvalidHouseName || isValidHouseDescription) {
      setIsSubmitConfirmation(true);
    } else {
      house ? handleUpdateSubmit() : handleSubmit();
    }
  };

  const handleSubmit = async () => {
    let {
      propertyName,
      country,
      state,
      zipCode,
      monthlyPrice,
      securityPrice,
      houseType,
      floorCount,
      bedroomCount,
      bathroomCount,
      propertyOption,
      propertyAreaCount,
      leasePeriodCount,
      fullAddress,
      aprt_suite,
      city,
      propertyDetail,

      // houseUtility,
      // utilityPrice,
    } = inputData;

    propertyName = makeValidHousename(propertyName);
    propertyDetail = makeValidHouseDescription(propertyDetail);

    if (
      houseImages?.length <= 0 ||
      !propertyName ||
      !country ||
      !state ||
      !zipCode ||
      !zipRegex.test(zipCode) ||
      !monthlyPrice ||
      !securityPrice ||
      !houseType ||
      !floorCount ||
      !bedroomCount ||
      !bathroomCount ||
      !propertyOption ||
      !propertyAreaCount ||
      !leasePeriodCount ||
      !fullAddress ||
      // !aprt_suite ||
      !city ||
      !propertyDetail ||
      houseDocImages?.length <= 0
      //houseUtility?.length === 0
      // (!isUtilityIncludedPrice && utilityPrice <= 0)
    ) {
      setInputError(true);
      setBtnLoader(false);
    } else {
      try {
        setInputError(false);

        setBtnLoader(true);
        let data = {
          name: propertyName,
          country: country,
          state: state,
          zip_code: zipCode,
          monthly_price: monthlyPrice,
          security_prices: securityPrice,
          house_type: houseType,
          floor: floorCount,
          property_details: propertyDetail,
          area: propertyAreaCount,
          bathroom_no: parseFloat(bathroomCount),
          bedroom_no: bedroomCount,
          amenities: houseAmenities?.toString(),
          // facing_side: formData.facing_side,
          house_facility: propertyOption,
          full_address: `${fullAddress};${aprt_suite};${city}`,
          house_images: houseImages?.map((val) => val?.imageUrl),
          house_documents: houseDocImages?.map((val) => val?.imageUrl),
          min_lease_period: leasePeriodCount,
          //utilityName: JSON.stringify(houseUtility),
          utilities: JSON.stringify(utilityData),

          total_utilities_price: utilityPrice,
          utilities_price_included: isUtilityIncludedPrice,
          is_pets_allowed: petAllowed,
        };

        console.log("datatest", data);
        const res = await axios.post(
          `${import.meta.env.VITE_APP_BASE_URL}/api/house/create`,
          data,
          { headers: headerJson }
        );
        setBtnLoader(false);
        if (res) {
          toggleComponentHouse();
        }
      } catch (err) {
        setBtnLoader(false);
        console.log("Error while adding property", err?.message);
      }
    }
  };

  const handleUpdateSubmit = async () => {
    let {
      propertyName,
      country,
      state,
      zipCode,
      monthlyPrice,
      securityPrice,
      houseType,
      floorCount,
      bedroomCount,
      bathroomCount,
      propertyOption,
      propertyAreaCount,
      leasePeriodCount,
      fullAddress,
      aprt_suite,
      city,
      propertyDetail,
      propertyUtilityPrice,
      isPetAllow,
      utilitiesPriceIncluded,
    } = inputData;
    propertyName = makeValidHousename(propertyName);
    propertyDetail = makeValidHouseDescription(propertyDetail);

    if (
      houseImages?.length <= 0 ||
      !propertyName ||
      !country ||
      !state ||
      !zipCode ||
      !zipRegex.test(zipCode) ||
      !monthlyPrice ||
      !securityPrice ||
      !houseType ||
      !floorCount ||
      !bedroomCount ||
      !bathroomCount ||
      !propertyOption ||
      !propertyAreaCount ||
      !leasePeriodCount ||
      !fullAddress ||
      // !aprt_suite ||
      !city ||
      !propertyDetail ||
      houseDocImages?.length <= 0
    ) {
      setInputError(true);
    } else {
      setInputError(false);
      setBtnLoader(true);
      let data = {
        name: propertyName,
        country: country,
        state: state,
        zip_code: zipCode,
        monthly_price: monthlyPrice,
        security_prices: securityPrice,
        house_type: houseType,
        floor: floorCount,
        property_details: propertyDetail,
        area: propertyAreaCount,
        bathroom_no: parseFloat(bathroomCount),
        bedroom_no: bedroomCount,
        amenities: houseAmenities?.toString(),
        //houseUtility:utilityData,
        // facing_side: formData.facing_side,
        house_facility: propertyOption,
        full_address: `${fullAddress};${aprt_suite};${city}`,
        house_images: houseImages,
        house_documents: houseDocImages,
        min_lease_period: leasePeriodCount,

        total_utilities_price: utilityPrice,
        utilities_price_included: isUtilityIncludedPrice,
        is_pets_allowed: petAllowed,
      };
      try {
        const res = await axios.put(
          `${import.meta.env.VITE_APP_BASE_URL}/api/house/update/${house?.id}`,
          data,
          { headers: headerJson }
        );
        setHouse(null);
        setBtnLoader(false);
        if (res) {
          toggleComponentHouse();
        }
      } catch (err) {
        setBtnLoader(false);
        console.log("Error while updating house", err?.message);
      }
    }
  };

  const handleHouseImages = async (e) => {
    try {
      const file = e.target.files[0];
      let formData = new FormData();
      formData.append("image", file);
      let res = await axios.post(
        `${import.meta.env.VITE_APP_BASE_URL}/api/image/upload`,
        formData,
        { headers: headersFormData }
      );
      setHouseImages([...houseImages, res?.data?.data]);
    } catch (err) {
      console.log("Error while adding house images", err?.message);
    }
  };

  const removeImage = (index) => {
    let url_data = [...houseImages];
    url_data?.splice(index, 1);
    setHouseImages(url_data);
  };

  const handleHouseDocs = async (e) => {
    try {
      const file = e.target.files[0];
      let formData = new FormData();
      formData.append("image", file);
      let res = await axios.post(
        `${import.meta.env.VITE_APP_BASE_URL}/api/image/upload`,
        formData,
        { headers: headersFormData }
      );
      setHouseDocImages([...houseDocImages, res?.data?.data]);
    } catch (err) {
      console.log("Error while adding house property papers", err?.message);
    }
  };

  const removeDoc = (index) => {
    let url_data = [...houseDocImages];
    url_data?.splice(index, 1);
    setHouseDocImages(url_data);
  };

  const handleCheckboxChange = (amenity) => {
    const index = houseAmenities?.indexOf(amenity);
    if (index === -1) {
      setHouseAmenities([...houseAmenities, amenity]);
    } else {
      const updatedAmenities = [...houseAmenities];
      updatedAmenities.splice(index, 1);
      setHouseAmenities(updatedAmenities);
    }
  };

  //animal allowed handle

  const handleAnimalAllowed = (event) => {
    setPetAllowed(event.target.value === "yes");
  };

  // const handleIncludePriceChange = (e) => {
  //   const value = e.target.value;
  //   setUtilityPrice(value);

  //   // Validate price if price validation is enabled
  //   // if (!isUtilityIncludedPrice && value <= 0) {
  //   //   setInputError(true);
  //   // } else {
  //   //   setInputError(false);
  //   // }
  // };

  console.log("input error,", inputError);

  // const handleCheckboxUtility = (ele) => {
  //   setHouseUtility((prevHouseUtility) => {
  //     if (prevHouseUtility.some((item) => item.name === ele.name)) {
  //       return prevHouseUtility.filter((item) => item.name !== ele.name);
  //     } else {
  //       return [...prevHouseUtility, { ...ele, price: "0" }];
  //     }
  //   });
  // };

  const handleCheckboxUtility = (ele) => {
    setHouseUtility((prevHouseUtility) => {
      if (prevHouseUtility.some((item) => item.name === ele.name)) {
        return prevHouseUtility.filter((item) => item.name !== ele.name);
      } else {
        const { price, ...newEle } = ele; // Destructure to exclude 'price'
        return [...prevHouseUtility, newEle];
      }
    });
  };

  //get utility

  const headers = {
    Authorization: user?.token,
  };
  const getHouseUtility = async () => {
    const res = await axios.get(
      `${import.meta.env.VITE_APP_BASE_URL}/api/house/getUtilities`,
      {
        headers: headers,
      }
    );
    // setIsLoader(false);
    if (res) {
      setUtilityData(res.data.data);
      console.log("response utility", res.data.data);
    } else {
      console.log("error");
    }
  };

  // const handlePriceChange = (utility, price) => {
  //   setHouseUtility((prev) =>
  //     prev.map((item) => (item.name === utility ? { ...item, price } : item))
  //   );
  // };

  const handlePriceChange = (ele, value) => {
    setHouseUtility((prevHouseUtility) =>
      prevHouseUtility.map((item) =>
        item.name === ele.name ? { ...item, price: value } : item
      )
    );
  };

  useEffect(() => {
    if (house) {
      let img_s = [];
      img_s = house?.houseImages?.map((ele) => {
        return ele;
      });
      setHouseImages(img_s);

      let docs = [];
      docs = house?.houseDocuments?.map((ele) => {
        return ele;
      });
      setHouseDocImages(docs);
    }
  }, [house]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  const submitPrice = () => {};

  const validateAndSubmit = () => {
    const isValid = houseUtility.every((item) => item.price !== "");
    if (isValid) {
      setError(false);
      submitPrice();
    } else {
      setError(true);
    }
  };

  const handleRadioChange = (e) => {
    // debugger;
    isSetUtilityIncludePrice((prev) => !prev);

    setUtilityPrice(0);
    // const value = e.target.value === 'yes';
    // isSetUtilityIncludePrice(value);

    // // Reset the utility price and input error when "Yes" is selected
    // if (value) {
    //   setUtilityPrice('');
    //   setError(false);
    // }
  };

  //address dropdown
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    getHouseUtility();
  }, []);

  return (
    <main>
      <div className="container-fluid px-4">
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="main-box">
              <div className="content-header">
                <h3>{house ? "Update" : "Add New"} House</h3>
                <div
                  onClick={() => {
                    setHouse(null);
                    toggleComponentHouse();
                  }}
                  className="back_arrow clickable"
                  title="Click here to go back."
                >
                  <i className="fa fa-solid fa-arrow-left fa-lg"></i>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="add_new_img_upload">
                    <div className="house_upload_head">
                      Upload your House Images
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="add_new_house">
                          <div className="Neon Neon-theme-dragdropbox">
                            <input
                              id="filer_input2"
                              name="files[]"
                              type="file"
                              accept=".jpg, .png, .jpeg"
                              onChange={(e) => handleHouseImages(e)}
                              onClick={(e) => (e.target.value = null)}
                              disabled={
                                house && house.booking_status === "Booked"
                                  ? true
                                  : false
                              }
                            />
                            <div className="Neon-input-dragDrop">
                              <div className="Neon-input-inner">
                                <div className="Neon-input-icon">
                                  <img src={UploadImg} />
                                </div>
                                <div className="Neon-input-text">
                                  <p>
                                    Drop your file here to upload or select from
                                    storage
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {inputError && houseImages?.length <= 0 && (
                            <div className="sap-val-error text-danger mb-0 mt-1">
                              Please upload your house images!
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="add_new_progres">
                          {houseImages?.length > 0 &&
                            houseImages?.map((value, i) => (
                              <div key={i} className="upload_jpg">
                                <div className="doc_img">
                                  <img
                                    src={
                                      import.meta.env.VITE_APP_BASE_URL +
                                      value?.imageUrl
                                    }
                                    alt="histhomeimg"
                                  />
                                </div>

                                <div
                                  key={i + "_images"}
                                  className="file_jpg_main"
                                >
                                  <div className="file_jpg">
                                    {/* <h4>{value?.name}</h4> */}
                                    {/* <div style={{ width: 50, height: 50 }}>
                    <CircularProgressbar
                      value={100}
                      text={`${100}%`}
                      styles={buildStyles({
                        textSize: '24px',
                        pathColor: '#3e98c7',
                        textColor: '#3e98c7',
                      })}
                    />
                  </div> */}
                                  </div>
                                </div>
                                <div
                                  className="close_icon"
                                  onClick={() => removeImage(i)}
                                >
                                  <img
                                    src={CloseCircleSvg}
                                    alt="close-circle"
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className="m-0" />
                  <div className="new_house_field_main">
                    <div className="row">
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3 new_house_field">
                          <label htmlFor="propertyName" className="form-label">
                            Property Name
                          </label>
                          <Tooltip title='Follow the format: (Verb, Noun, City, and State). Example "Beautiful home in Forest Hills New York.","Yummy Condo in Jackson Height, Queens"'>
                            <input
                              type="text"
                              className="form-control"
                              id="propertyName"
                              placeholder="Enter property name"
                              name="name"
                              value={inputData?.propertyName}
                              onKeyPress={(e) => {
                                if (!/^[a-zA-Z \s]+$/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                let text = e.target.value;
                                if (text.length > 100) {
                                  text = text.substring(0, 100);
                                }
                                text = text.replace(
                                  /\b(?:one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty|thirty|forty|fifty|sixty|seventy|eighty|ninety|hundred|thousand|million|billion)\b/gi,
                                  ""
                                );
                                text = text.replace(/\b\d+\b/g, "");
                                setInputData({
                                  ...inputData,
                                  propertyName: text,
                                });
                              }}
                              disabled={
                                house && house.booking_status === "Booked"
                                  ? true
                                  : false
                              }
                            />
                          </Tooltip>
                          {inputError && !inputData?.propertyName && (
                            <div className="sap-val-error text-danger mb-0 mt-1">
                              Please enter your property name!
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3 new_house_field">
                          <label htmlFor="monthlyPrice" className="form-label">
                            Enter Monthly Price{" "}
                          </label>
                          {/* <input
                            type="tel"
                            className="form-control"
                            id="monthlyPrice"
                            placeholder="Enter Monthly Price"
                            name="monthly_price"
                            value={inputData?.monthlyPrice}
                            onKeyPress={(e) => {
                              if (!/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                monthlyPrice: e.target.value,
                              })
                            }
                            disabled={
                              house && house.booking_status === "Booked"
                                ? true
                                : false
                            }
                          /> */}
                          <InputNumber
                            // className="form-control"
                            min={1}
                            max={100000000}
                            //style={{width:"100%"}}
                            prefix="$"
                            id="monthlyPrice"
                            placeholder="Enter Monthly Price"
                            name="monthly_price"
                            value={inputData?.monthlyPrice}
                            onKeyPress={(e) => {
                              if (!/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(value) =>
                              setInputData({
                                ...inputData,
                                monthlyPrice: value,
                              })
                            }
                            disabled={
                              house && house.booking_status === "Booked"
                                ? true
                                : false
                            }
                          />
                          {inputError && !inputData?.monthlyPrice && (
                            <div className="sap-val-error text-danger mb-0 mt-1">
                              Please enter monthly price!
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3 new_house_field">
                          <label htmlFor="securityPrice" className="form-label">
                            Enter Security price
                          </label>
                          <InputNumber
                            type="tel"
                            prefix="$"
                            min={0}
                            max={100000000}
                            // className="form-control"
                            id="securityPrice"
                            placeholder="Enter Security Price "
                            name="security_prices"
                            value={inputData?.securityPrice}
                            onKeyPress={(e) => {
                              if (!/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(value) =>
                              setInputData({
                                ...inputData,
                                securityPrice: value,
                              })
                            }
                            disabled={
                              house && house.booking_status === "Booked"
                                ? true
                                : false
                            }
                          />
                          {inputError && !inputData?.securityPrice && (
                            <div className="sap-val-error text-danger mb-0 mt-1">
                              Please enter security price!
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3 new_house_field new_house_select">
                          <label htmlFor="houseType" className="form-label">
                            House Type
                          </label>
                          <select
                            id="houseType"
                            className="form-select"
                            aria-label="Default select example"
                            name="house_type"
                            value={inputData?.houseType}
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                houseType: e.target.value,
                              })
                            }
                            disabled={
                              house && house.booking_status === "Booked"
                                ? true
                                : false
                            }
                          >
                            <option value={"Apartment"}>Apartment</option>
                            <option value={"Villa"}>Villa</option>
                            <option value={"House"}>House</option>
                            <option value={"Office"}>Office</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3 new_house_field">
                          <label htmlFor="floorCount" className="form-label">
                            Enter Floor
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            id="floorCount"
                            name="floor"
                            placeholder="2"
                            value={inputData?.floorCount}
                            onKeyPress={(e) => {
                              if (!/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                floorCount: e.target.value,
                              })
                            }
                            disabled={
                              house && house.booking_status === "Booked"
                                ? true
                                : false
                            }
                          />
                          {inputError && !inputData?.floorCount && (
                            <div className="sap-val-error text-danger mb-0 mt-1">
                              Please enter floor!
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3 new_house_field">
                          <label htmlFor="numBedrooms" className="form-label">
                            Enter Number Of Bedrooms
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            id="numBedrooms"
                            name="bedroom_no"
                            placeholder="4"
                            value={inputData?.bedroomCount}
                            onKeyPress={(e) => {
                              if (!/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                bedroomCount: e.target.value,
                              })
                            }
                            disabled={
                              house && house.booking_status === "Booked"
                                ? true
                                : false
                            }
                          />
                          {inputError && !inputData?.bedroomCount && (
                            <div className="sap-val-error text-danger mb-0 mt-1">
                              Please enter number of bedrooms!
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3 new_house_field">
                          <label htmlFor="numBathroom" className="form-label">
                            Enter Number Of Bathrooms
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="numBathroom"
                            name="bathroom_no"
                            placeholder="2"
                            value={inputData?.bathroomCount}
                            onChange={(e) => {
                              if (/^\d*\.?(?:0|5)?$/.test(e.target.value)) {
                                setInputData({
                                  ...inputData,
                                  bathroomCount: e.target.value,
                                });
                              }
                            }}
                            disabled={
                              house && house.booking_status === "Booked"
                                ? true
                                : false
                            }
                          />
                          {inputError && !inputData?.bathroomCount && (
                            <div className="sap-val-error text-danger mb-0 mt-1">
                              Please enter number of bathrooms!
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3 new_house_field new_house_select">
                          <label
                            htmlFor="propertyOptions"
                            className="form-label"
                          >
                            Property Options
                          </label>
                          <select
                            id="propertyOptions"
                            className="form-select"
                            aria-label="Default select example"
                            name="house_facility"
                            value={inputData?.propertyOption}
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                propertyOption: e.target.value,
                              })
                            }
                            disabled={
                              house && house.booking_status === "Booked"
                                ? true
                                : false
                            }
                          >
                            <option value={"Fully Furnished"}>
                              Fully Furnished
                            </option>
                            <option value={"Customize"}>Unfurnished</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3 new_house_field">
                          <label htmlFor="propertyArea" className="form-label">
                            SQ FT
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            id="propertyArea"
                            name="area"
                            placeholder="1500"
                            value={inputData?.propertyAreaCount}
                            onKeyPress={(e) => {
                              if (!/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                propertyAreaCount: e.target.value,
                              })
                            }
                            disabled={
                              house && house.booking_status === "Booked"
                                ? true
                                : false
                            }
                          />
                          {inputError && !inputData?.propertyAreaCount && (
                            <div className="sap-val-error text-danger mb-0 mt-1">
                              Please Enter Property SQ FT!
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="mb-3 new_house_field">
                          <label htmlFor="leaseCount" className="form-label">
                            Minimum Lease Period (in months)
                          </label>
                          <div className="input-group">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={() =>
                                setInputData((prevInputData) => ({
                                  ...prevInputData,
                                  leasePeriodCount: Math.max(
                                    prevInputData.leasePeriodCount - 1,
                                    1
                                  ),
                                }))
                              }
                              disabled={
                                house && house.booking_status === "Booked"
                                  ? true
                                  : false
                              }
                            >
                              -
                            </button>
                            <input
                              type="tel"
                              className="form-control"
                              id="leaseCount"
                              value={inputData?.leasePeriodCount}
                              onKeyPress={(e) => {
                                if (!/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  leasePeriodCount: e.target.value,
                                })
                              }
                              readOnly
                              disabled={
                                house && house.booking_status === "Booked"
                                  ? true
                                  : false
                              }
                            />
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={() =>
                                setInputData((prevInputData) => ({
                                  ...prevInputData,
                                  leasePeriodCount:
                                    prevInputData.leasePeriodCount + 1,
                                }))
                              }
                              disabled={
                                house && house.booking_status === "Booked"
                                  ? true
                                  : false
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                      <hr className="mt-3 p-1"></hr>
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3 new_house_field">
                            <label htmlFor="country" className="form-label">
                              Country
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="country"
                              placeholder="Country"
                              name="country"
                              value={inputData?.country}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  country: e.target.value,
                                })
                              }
                              disabled
                            />
                            {inputError && !inputData?.country && (
                              <div className="sap-val-error text-danger mb-0 mt-1">
                                Please enter your country!
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-9">
                          <div className="mb-3 new_house_field">
                            <label
                              htmlFor="exampleFormControlInput9"
                              className="form-label"
                            >
                              Address{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput9"
                              placeholder="Enter  Address"
                              name="address"
                              value={inputData?.fullAddress}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  fullAddress: e.target.value,
                                })
                              }
                              disabled={
                                house && house.booking_status === "Booked"
                                  ? true
                                  : false
                              }
                            />
                            {inputError && !inputData?.fullAddress && (
                              <div className="sap-val-error text-danger mb-0 mt-1">
                                Please enter your full address!
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3 new_house_field">
                            <label htmlFor="aprSuite" className="form-label">
                              Apartment/Suite
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="aprSuite"
                              placeholder="Apartment/Suite"
                              name="aprSuite"
                              value={inputData?.aprt_suite}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  aprt_suite: e.target.value,
                                })
                              }
                              disabled={
                                house && house.booking_status === "Booked"
                                  ? true
                                  : false
                              }
                            />
                            {/* {inputError && !inputData?.aprt_suite && (
                                <div className="sap-val-error text-danger mb-0 mt-1">
                                  Please enter your apartment no. or suite!
                                </div>
                              )} */}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3 new_house_field">
                            <label htmlFor="city" className="form-label">
                              City
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              placeholder="City"
                              name="city"
                              value={inputData?.city}
                              onKeyPress={(e) => {
                                if (!/^[a-zA-Z \s]+$/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  city: e.target.value,
                                })
                              }
                              disabled={
                                house && house.booking_status === "Booked"
                                  ? true
                                  : false
                              }
                            />
                            {inputError && !inputData?.city && (
                              <div className="sap-val-error text-danger mb-0 mt-1">
                                Please enter your city!
                              </div>
                            )}
                          </div>
                        </div>

                        {/* <div className="col-lg-3 col-md-6">
                          <div className="mb-3 new_house_field">
                            <label htmlFor="state" className="form-label">
                              State
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="state"
                              placeholder="State"
                              name="state"
                              value={inputData?.state}
                              onKeyPress={(e) => {
                                if (!/^[a-zA-Z \s]+$/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  state: e.target.value,
                                })
                              }
                              disabled={
                                house && house.booking_status === "Booked"
                                  ? true
                                  : false
                              }
                            />
                            {inputError && !inputData?.state && (
                              <div className="sap-val-error text-danger mb-0 mt-1">
                                Please enter your state!
                              </div>
                            )}
                          </div>
                        </div> */}

                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3 new_house_field">
                            <label htmlFor="state" className="form-label">
                              State
                            </label>
                            <select
                              style={{ height: "56px" }}
                              className="form-control"
                              id="state"
                              name="state"
                              value={inputData?.state}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  state: e.target.value,
                                })
                              }
                              disabled={
                                house && house.booking_status === "Booked"
                              }
                            >
                              <option value="">Select a State</option>
                              {states.map((state, index) => (
                                <option key={index} value={state}>
                                  {state}
                                </option>
                              ))}
                            </select>
                            {inputError && !inputData?.state && (
                              <div className="sap-val-error text-danger mb-0 mt-1">
                                Please enter your state!
                              </div>
                            )}
                          </div>
                        </div>

                        {/* <select style={{width:"100px",}}>
      {states.map((state, index) => (
        <option key={index} value={state}>
          {state}
        </option>
      ))}
    </select> */}
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3 new_house_field">
                            <label htmlFor="zipCode" className="form-label">
                              Zip Code
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="zipCode"
                              name="zip_code"
                              placeholder="Zip Code"
                              value={inputData?.zipCode}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  zipCode: e.target.value,
                                })
                              }
                              disabled={
                                house && house.booking_status === "Booked"
                                  ? true
                                  : false
                              }
                            />
                            {inputError &&
                              (!inputData?.zipCode ||
                                !zipRegex.test(inputData?.zipCode)) && (
                                <div className="sap-val-error text-danger mb-0 mt-1">
                                  {!inputData?.zipCode
                                    ? "Please enter your zip code!"
                                    : !zipRegex.test(inputData?.zipCode)
                                    ? "Invalid zip code"
                                    : ""}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <hr className="mt-3 p-1"></hr>
                      <div className="col-12">
                        <div className="mb-3 new_house_field">
                          <label
                            htmlFor="propertyDetail"
                            className="form-label"
                          >
                            Property Details
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="propertyDetail"
                            placeholder="Property Details..."
                            name="property_details"
                            value={inputData?.propertyDetail}
                            onKeyPress={(e) => {
                              if (!/^[a-zA-Z \-,.]+$/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                propertyDetail: e.target.value,
                              })
                            }
                            disabled={
                              house && house.booking_status === "Booked"
                                ? true
                                : false
                            }
                          />
                          {inputError && !inputData?.propertyDetail && (
                            <div className="sap-val-error text-danger mb-0 mt-1">
                              Please enter property details!
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="owner_doc">
                          <h4>
                            {user?.currentRole === "property_manager"
                              ? "Upload Property Management Agreement and House Ownership Agreement Document"
                              : "Upload House Ownership Document"}{" "}
                            <span className="text-danger">
                              (*Accept only .pdf, .docx)
                            </span>
                          </h4>
                          <div className="owner_doc_inner">
                            <div className="Neon Neon-theme-dragdropbox">
                              <input
                                id="filer_input2"
                                name="files[]"
                                type="file"
                                accept=".pdf, .docx" // Specify the accepted file types
                                onChange={(e) => handleHouseDocs(e)}
                                onClick={(e) => (e.target.value = null)}
                                disabled={
                                  house && house.booking_status === "Booked"
                                    ? true
                                    : false
                                }
                              />
                              <div className="Neon-input-dragDrop">
                                <div className="Neon-input-inner">
                                  <div className="Neon-input-icon">
                                    <img src={OwnerPlusSvg} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {houseDocImages?.length > 0 &&
                              houseDocImages?.map((value, i) => (
                                <div key={i + "_docs"} className="owner_img">
                                  <div
                                    className="close_icon"
                                    onClick={() =>
                                      !(house &&
                                      house.booking_status === "Booked"
                                        ? true
                                        : false) && removeDoc(i)
                                    }
                                  >
                                    <img
                                      src={CloseCircleSvg}
                                      alt="close-circle`"
                                    />
                                  </div>
                                  <img
                                    style={{ width: "50px", height: "50px" }}
                                    src={
                                      import.meta.env.VITE_APP_BASE_URL +
                                      value?.imageUrl
                                    }
                                    alt={value?.name}
                                  />
                                </div>
                              ))}
                            {inputError && houseDocImages?.length <= 0 && (
                              <div className="sap-val-error text-danger mb-0 mt-2 ms-3">
                                Please upload your property files!
                              </div>
                            )}
                          </div>

                          <hr className="mt-3 p-1"></hr>
                          <h4>
                            Are utilities included in the house price? &nbsp;
                            <label style={{ margin: "20px" }}>
                              <input
                                type="radio"
                                className="me-2"
                                name="utilities_price_included"
                                value={isUtilityIncludedPrice}
                                checked={isUtilityIncludedPrice === true}
                                onChange={handleRadioChange}
                              />
                              Yes
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="utilities_price_included"
                                value={isUtilityIncludedPrice}
                                className="me-2"
                                checked={isUtilityIncludedPrice === false}
                                onChange={handleRadioChange}
                              />
                              No
                            </label>
                          </h4>
                          <label
                            htmlFor="propertyDetail"
                            className="mb-1 new_house_field"
                          >
                            Utilities
                          </label>

                          <div className="check_amenit check_amenit_two">
                            {utilityData.map((ele) => (
                              <div className="form-check" key={ele.id}>
                                <label
                                  className="form-check-label"
                                  htmlFor={ele.id}
                                >
                                  {ele.name}
                                </label>

                                {/* <div>
                                  <Input
                                    type="text"
                                    id="premiumPrice"
                                    addonBefore="$"
                                    placeholder="Price"
                                    value={
                                      houseUtility.find(
                                        (item) => item.name === ele.name
                                      )?.price || ""
                                    }
                                    addonAfter="/month"
                                    onKeyPress={(e) => {
                                      if (
                                        !/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) =>
                                      handlePriceChange(ele, e.target.value)
                                    }
                                    style={{ width: "150px" }}
                                    disabled={isDisabled}
                                  />
                                  {error && !ele.price && (
                                    <div className="sap-val-error text-danger mb-0 mt-1">
                                      Please enter price!
                                    </div>
                                  )}
                                </div> */}
                              </div>
                            ))}

                            {/* {inputError && houseUtility?.length === 0 && (
                              <div className="sap-val-error text-danger mb-0 mt-1">
                                Please select utility
                              </div>
                            )} */}
                          </div>

                          <div className="check_amenit">
                            {/* {utilityData.map((ele, i) => (
                              <div className="form-check" key={ele.id}>
                                <input
                                  id={ele.id}
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={houseUtility.some(
                                    (item) => item.name === ele.name
                                  )}
                                  onChange={() => handleCheckboxUtility(ele)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={ele.id}
                                >
                                  {ele.name}
                                </label> */}

                            {/* <div>
                                  <Input
                                    type="text"
                                    id="premiumPrice"
                                    addonBefore="$"
                                    placeholder="Price"
                                    value={
                                      houseUtility.find(
                                        (item) => item.name === ele.name
                                      )?.price || ""
                                    }
                                    addonAfter="/month"
                                    onKeyPress={(e) => {
                                      if (
                                        !/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) =>
                                      handlePriceChange(ele, e.target.value)
                                    }
                                    style={{ width: "150px" }}
                                    disabled={isDisabled}
                                  />
                                  {error && !ele.price && (
                                    <div className="sap-val-error text-danger mb-0 mt-1">
                                      Please enter price!
                                    </div>
                                  )}
                                </div> */}
                          </div>
                          {/* ))}

                            {inputError && houseUtility?.length === 0 && (
                              <div className="sap-val-error text-danger mb-0 mt-1">
                                Please select utility
                              </div>
                            )}
                          </div> */}
                          <div className="col-lg-3 col-md-6">
                            <div className="mb-3 new_house_field">
                              <label
                                htmlFor="monthlyPrice"
                                className="form-label"
                              >
                                Enter Utility Total Price
                                <Tooltip
                                  title="Please sum up all the amounts of utilities wherever applicable and indicate the aggregated amount in the Utility Amount field !"
                                  style={{ width: "20px" }}
                                >
                                  {" "}
                                  <i className="fa-solid fa-circle-info"></i>
                                </Tooltip>
                              </label>

                              {/* <input
                                type="tel"
                                className="form-control"
                                id="monthlyPrice"
                                placeholder="Enter Utility Total  Price"
                                name="total_utilities_price"
                                disabled={isUtilityIncludedPrice}
                                value={utilityPrice}
                                onChange={(e) =>
                                  setUtilityPrice(e.target.value)
                                }
                              /> */}

                              <InputNumber
                                // className="form-control"
                                min={0}
                                max={100000000}
                                // style={{width:"100%"}}
                                prefix="$"
                                //  addonAfter="/month"
                                placeholder="Enter Utility Total  Price"
                                name="total_utilities_price"
                                disabled={isUtilityIncludedPrice}
                                value={utilityPrice}
                                onChange={(value) => {
                                  setUtilityPrice(value);
                                }}
                              />

                              {!isUtilityIncludedPrice &&
                                inputError &&
                                !utilityPrice > 0 && (
                                  <div className="sap-val-error text-danger mb-0 mt-1">
                                    Please Enter Price
                                  </div>
                                )}
                            </div>
                          </div>

                          {/* pet allowed */}

                          <div>
                            <h4
                              className="mr-5"
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              Pets Allowed : {"   "}
                              <div className="d-flex align-items-center ml-5 ms-2">
                                <div className="form-check form-check-inline me-2 ">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="is_pets_allowed"
                                    value="yes"
                                    checked={petAllowed === true}
                                    onChange={handleAnimalAllowed}
                                  />
                                  <label className="form-check-label">
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check form-check-inline me-2">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="is_pets_allowed"
                                    value="no"
                                    checked={petAllowed === false}
                                    onChange={handleAnimalAllowed}
                                  />
                                  <label className="form-check-label">No</label>
                                </div>
                              </div>
                            </h4>
                          </div>

                          <h4>Add Amenities and Overview</h4>
                          <div className="check_amenit">
                            {amenitiesData &&
                              amenitiesData?.map((ele, i) => {
                                return (
                                  <div className="form-check" key={i}>
                                    <input
                                      id={ele}
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={houseAmenities?.includes(ele)}
                                      onChange={() => handleCheckboxChange(ele)}
                                      disabled={
                                        house &&
                                        house.booking_status === "Booked"
                                          ? true
                                          : false
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={ele}
                                    >
                                      {ele}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="br_btn amenit">
                            <button
                              onClick={handleConfirmForSubmit}
                              type="button"
                              disabled={btnLoader}
                              hidden={
                                house && house.booking_status === "Booked"
                                  ? true
                                  : false
                              }
                            >
                              Save Details
                              {btnLoader && <Spinner />}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={iSubmitConfirmation}
        // onOk={handleSubmit}
        onCancel={() => setIsSubmitConfirmation(false)}
        footer={null}
        title={
          <span style={{ fontSize: "20px", fontWeight: "bold" }}>
            Restricted Words Detected
          </span>
        }
      >
        <p>
          Your submission included restricted words (like addresses, emails, or
          phone numbers). To proceed, we updated the house name for you
        </p>
        <p>
          <strong>Updated Property Name:</strong>{" "}
          {makeValidHousename(inputData?.propertyName) ||
            inputData?.propertyName}
        </p>
        <p>
          <strong>Updated Property detail:</strong>{" "}
          {makeValidHouseDescription(inputData?.propertyDetail) ||
            inputData?.propertyDetail}
        </p>
        <div className="modal-footer" style={{ marginTop: "20px" }}>
          <Button
            type="primary"
            onClick={() => {
              house ? handleUpdateSubmit() : handleSubmit();
            }}
            style={{ marginRight: "8px" }}
          >
            Proceed with Updated Name
          </Button>
          <Button onClick={() => setIsSubmitConfirmation(false)}>
            Edit Name Again
          </Button>
        </div>
      </Modal>
    </main>
  );
}

export default OwnerAddHouse;
