import { useState, useEffect, useContext } from "react";
import Logo from "../assets/images/logo.png";
import Navavtar from "../assets/images/navavtar.png";
import Banner from "../assets/images/banner.png";
import NavToggle from "../assets/images/navToggle.svg";
import "./custom.css";
import "./styles.css";
import SideNav from "./SideNav";
import { useUserState, useUserDispatch } from "../Contexts/UserContext";
import Bell from "../assets/images/Bell.svg";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { TimeCountDown } from "./TimeCountDown";
import { NotificationContext } from "../Contexts/NotificationContext";

function UserDash() {
  const { user, isLoggedIn, unreadNotificationCount } = useUserState();
  const dispatch = useUserDispatch();

  //user date from context
  // Initialize the sidebar toggle state with the value from localStorage (if available)
  const [isSidebarToggled, setIsSidebarToggled] = useState(
    localStorage.getItem("sb|sidebar-toggle") === "true"
  );

  const { notificationList } = useContext(NotificationContext);

  // Add an event listener to handle the sidebar toggle
  useEffect(() => {
    const handleSidebarToggle = () => {
      // Toggle the state and update localStorage
      setIsSidebarToggled((prevState) => {
        const newState = !prevState;
        localStorage.setItem("sb|sidebar-toggle", newState);
        return newState;
      });
    };

    // Attach the event listener to the button
    const sidebarToggle = document.body.querySelector("#sidebarToggle");
    if (sidebarToggle) {
      sidebarToggle.addEventListener("click", handleSidebarToggle);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (sidebarToggle) {
        sidebarToggle.removeEventListener("click", handleSidebarToggle);
      }
    };
  }, []);

  // Add the 'sb-sidenav-toggled' className based on the state
  useEffect(() => {
    if (isSidebarToggled) {
      document.body.classList.add("sb-sidenav-toggled");
    } else {
      document.body.classList.remove("sb-sidenav-toggled");
    }
  }, [isSidebarToggled]);

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    // navigate("/login");
  };

  console.log("user", user);

  return (
    <div className="sb-nav-fixed">
      <div className="myheader">
        <nav className="sb-topnav navbar navbar-expand">
          {/* <!-- Navbar Brand--> */}
          <Link className="navbar-brand ps-3" to={`/`}>
            <img src={Logo} />
          </Link>

          {/* <!-- Sidebar Toggle--> */}
          <button
            className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
            id="sidebarToggle"
            href="#!"
          >
            <img src={NavToggle} />
          </button>
          {/* <!-- Navbar Search--> */}

          {/* <!-- Navbar--> */}
          <ul className="navbar-nav ms-auto align-items-lg-center">
            {isLoggedIn ? (
              <>
                <div className=" d-flex align-items-center justify-content-center"></div>
                <li className="nav-item   custom-menu timer-sec-menu">
                  <Link
                    className="nav-link"
                    to={`/user_dash/${user?.currentRole}/notifications`}
                  >
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <div className="sb-nav-link-icon sb-nav-link-icon-bell">
                        <img src={Bell} alt="notifications" />
                        {/* <i className="fa-solid fa-bell"></i> */}
                      </div>
                      {unreadNotificationCount > 0 && (
                        <div
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            fontSize: "12px",
                          }}
                        >
                          {unreadNotificationCount}
                        </div>
                      )}
                    </div>
                    <div>
                      {["tenant", "relo"].includes(user?.currentRole) && (
                        <TimeCountDown />
                      )}
                    </div>
                  </Link>
                </li>
              </>
            ) : null}
            {user && ["tenant", "relo"].includes(user?.currentRole) && (
              <li className="nav-item">
                <Link className="nav-link" to={`/`}>
                  View properties
                </Link>
              </li>
            )}
            <li className="nav-item custom-menu">
              <Link className="nav-link" to="/contact-us">
                Contact Us
              </Link>
            </li>
            <li className="nav-item dropdown profile">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  style={{ height: "100%" }}
                  src={user?.profile_img ? user?.profile_img : Navavtar}
                />
              </a>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link
                    className="dropdown-item"
                    to={`/user_dash/${user?.currentRole}`}
                  >
                    My Profile
                  </Link>
                </li>
                {/* <li>
                  <a className="dropdown-item" href="#!">
                    Activity Log
                  </a>
                </li> */}
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/"
                    onClick={() => {
                      localStorage.removeItem("beforeLoginPage");
                      handleLogout();
                    }}
                  >
                    Logout
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <div className="dash-banner-sec">
        <img src={Banner} />
      </div>
      <SideNav user={user?.data}></SideNav>
    </div>
  );
}

export default UserDash;
