import { Fragment, useEffect, useState } from "react";
import deleteImage from "../../assets/images/delete.png";
import editImage from "../../assets/images/Edit.png";
import CartBack from "../../assets/images/cart-back.png";
import CloseCircle from "../../assets/images/close-circle.svg";
import Cartplan1 from "../../assets/images/cartplan1.svg";
import Del from "../../assets/images/del.svg";
import Edit from "../../assets/images/Edit.svg";
import CartCoupon from "../../assets/images/cart-coupon.svg";
import { useUserState } from "../../Contexts/UserContext";
import axios from "axios";
import { Button, Spinner } from "react-bootstrap";
import ModalComponent from "../common/ModalComponents";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../common/Loader";

import FurnitureBackImg from "../../assets/images/furniture-back.png";
import Cart1Img from "../../assets/images/cart1.png";
import Cart2Img from "../../assets/images/cart2.png";
import Cart3Img from "../../assets/images/cart3.png";
import uploadImage from "../../assets/images/upload.png";
import addUserImage from "../../assets/images/add-user-icon.png";

const AllDataInCart = () => {
  const { user, isLoggedIn } = useUserState();
  const navigate = useNavigate();
  const location = useLocation();
  const [cartData, setCartData] = useState([]);
  const [finalCartData, setFinalCartData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [totalAmountDays, setTotalAmountDays] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [cardData, setCardData] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [subscriptionType, setSubscriptionType] = useState("monthly");
  const [monthlypay, setMonthlypay] = useState(null);
  const fromDate = location?.state?.startDate;
  const toDate = location?.state?.endDate;
  const [proceedAmount, setProceesAmount] = useState();
  const [days, setDays] = useState(null);
  const [noOfMonths, setNoOfMonths] = useState(null);
  const [noOfRemaingDays, setNoOfRemainingDays] = useState();
  const [totalMonths, setTotalMonths] = useState();
  const [openCleaningDetails, setOpenCleaningDetails] = useState(false);
  const [minimizeView, setMinimizeView] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [cartId, setCartId] = useState("");
  const [houseId, setHouseId] = useState("");
  const [isFurnitureDataShow, setIsFurnitureDataShow] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [urlData, setUrlData] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [isDisabledButtonIcons, setIsDisabledButtonIcons] = useState(false);
  const [newTenant, setNewTenant] = useState({
    id: null,
    name: null,
    age: null,
    gender: null,
    contact_info: null,
    document: null,
    main_cart_id: cartId,
  });
  const [inputTenant, setInputTenant] = useState(false);
  const [error, setError] = useState(null);
  const [editedTenant, setEditedTenant] = useState({
    id: null,
    name: null,
    age: null,
    gender: null,
    contact_info: null,
    document: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const cart = params.get("cart");
        const house = params.get("house");
        setCartId(cart);
        setHouseId(house);
        newTenant.main_cart_id = cartId;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [location.search]);

  if (!isLoggedIn) {
    navigate("/login");
  }
  function calculateDateDifference(startDate, endDate) {
    const differenceMs = endDate - startDate;
    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24)) + 1;
    setDays(differenceDays);
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    let diffYear = endDate.getFullYear() - startDate.getFullYear();
    let diffMonth = endDate.getMonth() - startDate.getMonth();
    let months = diffYear * 12 + diffMonth;
    let totalDaysInLstMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth() + 1,
      0
    ).getDate();
    if (months < 0 || (months === 0 && endDay < startDay)) {
      months += 12;
    }
    let remainingDays = endDay - startDay + 1;
    if (remainingDays === totalDaysInLstMonth) {
      months++, (remainingDays = 0);
    }
    if (remainingDays < 0) {
      months--;
      const tempDate = new Date(endDate);
      tempDate.setMonth(endDate.getMonth() - 1);
      remainingDays =
        new Date(tempDate.getFullYear(), tempDate.getMonth() + 1, 0).getDate() -
        startDay +
        endDay +
        1;
    }
    setTotalMonths(months + remainingDays / totalDaysInLstMonth);
    return {
      days: differenceDays,
      months: months,
      remainingDays: remainingDays,
    };
  }
  let counter = 1;

  const calculateInstallments = (
    startDate,
    endDate,
    totalAmount,
    securityDeposit
  ) => {
    const { months, remainingDays } = calculateDateDifference(
      startDate,
      endDate
    );
    let monthsRemaining = months;
    const installments = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const daysInMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();
      let daysToUse = monthsRemaining > 0 ? daysInMonth : remainingDays;
      monthsRemaining--;
      const installmentAmount = (daysToUse / daysInMonth) * totalAmount;
      const year = currentDate.getFullYear();
      const monthName = `${currentDate.toLocaleString("default", {
        month: "long",
      })}-${year}`;
      if (currentDate.getTime() === startDate.getTime()) {
        installments.push({
          month: `${monthName}( with security )`,
          amount: (installmentAmount + securityDeposit).toFixed(2),
        });
      } else {
        installments.push({
          month: monthName,
          amount: installmentAmount.toFixed(2),
        });
      }
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return { installments, remainingDays, months };
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const headers = {
    Authorization: user?.token,
  };

  const FetchData = async () => {
    const baseCartUrl = `${
      import.meta.env.VITE_APP_BASE_URL
    }/api/cart/get-by-house/${cartId}/${houseId}`;
    const baseCardUrl = `${
      import.meta.env.VITE_APP_BASE_URL
    }/api/payment-method/get-list`;
    let baseGetTenatUrl = `${
      import.meta.env.VITE_APP_BASE_URL
    }/api/relo/getTenant/${cartId}`;
    try {
      const res = await axios.get(baseCartUrl, { headers });
      const cardRes = await axios.get(baseCardUrl, { headers });
      const tenantRes =
        user?.currentRole === "relo"
          ? await axios.get(baseGetTenatUrl, { headers })
          : null;
      if (res.status === 200 && cardRes.status === 200) {
        let cartItems = res.data.data;
        setCardData(cardRes?.data?.data?.data);
        setCartData(cartItems);
      }
      if (tenantRes.status === 200) {
        setTenantList(tenantRes?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateCartTotal = (data) => {
    const mainCart = data?.mainCart;

    if (!mainCart || !mainCart.furnitureCarts) {
      return null; // or handle the absence of mainCart as appropriate
    }

    const {
      furnitureCarts,
      cleaningServiceCarts,
      house_data: houseData,
      parent_cart_id,
    } = mainCart;

    const {
      name: houseName = "",
      monthly_price: housePrice = 0,
      security_prices: securityPrice = 0,
      houseImages = [],
    } = houseData || {};
    const owner_offered_price =
      mainCart?.house_data?.priceNegotiateRequests[0]?.negotiationData[0]
        ?.owner_offered_price;
    const cust_offered_price =
      mainCart?.house_data?.priceNegotiateRequests[0]?.negotiationData[0]
        ?.cust_offered_price;
    const cartId =
      furnitureCarts?.length > 0
        ? furnitureCarts[0].main_cart_id
        : cleaningServiceCarts[0].main_cart_id;
    const houseImage = houseImages.length > 0 ? houseImages[0].imageUrl : null;

    let furnitureTotalPrice = 0;
    let cleaningTotalPrice = 0;

    const furnitureNames = [];
    const cleaningNames = [];

    for (const item of furnitureCarts) {
      const price = Number(item.furniture_data.price);
      const quantity = item.quantity;
      furnitureTotalPrice += price * quantity;
      furnitureNames.push(item.furniture_category_data.name);
    }
    for (const service of cleaningServiceCarts) {
      const price = Number(service.cleaning_service_data.price);
      const frequency = service.frequency;
      cleaningTotalPrice += price * frequency;

      cleaningNames.push(service.cleaning_service_data.name);
    }

    const subTotal = (
      parseFloat(furnitureTotalPrice) +
      parseFloat(cleaningTotalPrice) / parseFloat(totalMonths) +
      parseFloat(housePrice)
    ).toFixed(2);
    const total = (parseFloat(subTotal) + parseFloat(securityPrice)).toFixed(2);

    return {
      cartId,
      parent_cart_id,
      furniture_name: furnitureNames,
      furniture_total_price: furnitureTotalPrice,
      furniture_image:
        furnitureCarts[0]?.furniture_data.furniture_images[0]?.imageUrl,
      cleaning_name: cleaningNames,
      cleaning_total_price: cleaningTotalPrice,
      house_name: houseName,
      house_price: owner_offered_price || cust_offered_price || housePrice,
      houseImage: houseImage,
      security_price: securityPrice,
      subTotal,
      total,
    };
  };
  useEffect(() => {
    if (user?.token) {
      cartId && FetchData();
    }
  }, [user?.token, cartId, showModal]);
  useEffect(() => {
    try {
      if (Object.prototype.hasOwnProperty.call(cartData, "mainCart")) {
        const calculatedCartTotal = calculateCartTotal(cartData);
        setFinalCartData(calculatedCartTotal);
        const calculatedTotalAmountDays =
          (calculatedCartTotal?.subTotal * days) / 30;

        // setTotalAmountDays(calculatedTotalAmountDays);
        // setTotalAmount(
        //   (
        //     calculatedTotalAmountDays + calculatedCartTotal?.security_price
        //   ).toFixed(2)
        // );

        const { installments, remainingDays, months } = calculateInstallments(
          new Date(fromDate),
          new Date(toDate),
          calculatedCartTotal?.subTotal,
          calculatedCartTotal?.security_price
        );
        setNoOfMonths(months);
        setNoOfRemainingDays(remainingDays);
        setMonthlypay(installments);
        setProceesAmount(
          installments
            .reduce((acc, { amount }) => acc + parseFloat(amount), 0)
            .toFixed(2)
        );
        setTotalAmountDays(installments[0].amount);
        setTotalAmount(
          installments
            .reduce((acc, { amount }) => acc + parseFloat(amount), 0)
            .toFixed(2)
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [cartData, days]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (isChecked) {
      setProceesAmount(parseFloat(finalCartData?.total).toFixed(2));
      setSubscriptionType("monthly");
    } else {
      setProceesAmount(parseFloat(totalAmount).toFixed(2));
      setSubscriptionType("one_time");
    }
  }, [isChecked, totalAmount, finalCartData]);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    if (noOfMonths === 1 && noOfRemaingDays === 0) {
      setIsChecked(false);
    }
  }, [noOfMonths, noOfRemaingDays]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);
  const headerJson = {
    "Content-Type": "application/json",
    Authorization: user?.token,
  };
  const headersFormData = {
    "Content-Type": "multipart/form-data",
  };
  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append("tenant_document", file);
      let res = await axios.post(
        `${import.meta.env.VITE_APP_BASE_URL}/api/relo/upload`,
        formData,
        { headers: headersFormData }
      );
      if (isEditing) {
        const updatedTenantList = [...tenantList];
        updatedTenantList[isEditing].document = res?.data?.data?.docUrl;
        setTenantList(updatedTenantList);
      } else {
        setUrlData(
          import.meta.env.VITE_APP_BASE_URL + "/" + res?.data?.data?.docUrl
        );
        newTenant.document = res?.data?.data?.docUrl;
      }
    } catch (err) {
      return;
    }
  };
const handleAddTenant = async () => {
  setIsDisabledButtonIcons(true);
  try {
    // Validate the tenant name
    if (!newTenant?.name || !newTenant.name.trim()) {
      setError("Name is required.");
      setIsDisabledButtonIcons(false);
      return;
    }

    const baseAddTenantUrl = `${
      import.meta.env.VITE_APP_BASE_URL
    }/api/relo/addTenant`;

    // Perform API call to add tenant
    setError(null);
    const res = await axios.post(
      baseAddTenantUrl,
      { ...newTenant, main_cart_id: cartId },
      { headers: headerJson }
    );

    if (res.status === 201) {
      setTenantList(res.data.data); // Update tenant list with new data
      setNewTenant({
        id: "",
        name: "",
        age: "",
        gender: "",
        contact_info: "",
        document: "",
      }); // Reset new tenant form
      setUrlData(null); // Clear URL data if needed
    } else {
      // Handle unexpected status codes
      console.error("Failed to add tenant:", res.status, res.data);
      alert("Failed to add tenant. Please try again later.");
    }
  } catch (error) {
    console.log("Error while adding tenant:", error);
    alert(
      error.response?.data?.message ||
        "Failed to add tenant. Please try again later."
    );
  } finally {
    setIsDisabledButtonIcons(false); // Ensure the button is re-enabled
  }
};


  const handleEditTenant = (index) => {
    console.log("index",index)
    setIsEditing(index);
    setEditedTenant({ ...tenantList[index] }); // Set the selected tenant for editing
  };

  const handleUpdateTenant = async (index) => {
    const updateData = { ...editedTenant }; // Use the edited data

    console.log("Updating tenant at index:", index, updateData);
    setIsDisabledButtonIcons(true);

    try {
      // Validation
      if (!updateData.name.trim()) {
        setError("Name is required.");
        return;
      }
      setError(null);

      // API call to update tenant information
      const response = await fetch(
        `${import.meta.env.VITE_APP_BASE_URL}/api/relo/updateTenant/${
          updateData.id
        }`,
        {
          method: "POST",
          headers: headerJson,
          body: JSON.stringify(updateData),
        }
      );

      if (response.ok) {
        const updatedTenantList = [...tenantList];
        updatedTenantList[index] = updateData; // Update the tenant list with the edited data
        setTenantList(updatedTenantList);
        setIsEditing(null); // Exit edit mode
      } else {
        const errorText = await response.text();
        console.error("Failed to update tenant:", errorText);
        // setError("Failed to update tenant. Please try again.");
      }
    } catch (error) {
      console.error("Error updating tenant:", error);
      // setError("An error occurred. Please try again.");
    } finally {
      setIsDisabledButtonIcons(false); // Re-enable buttons/icons after the update
    }
  };

  const handleDeleteTenant = async (index) => {
    setIsDisabledButtonIcons(true);
    try {
      const updateData = tenantList[index];
      await fetch(
        `${import.meta.env.VITE_APP_BASE_URL}/api/relo/deleteTenant`,
        {
          method: "POST",
          headers: headerJson,
          body: JSON.stringify({ id: updateData.id }),
        }
      );
      const updatedTenantList = tenantList.filter((_, i) => i !== index);
      setTenantList(updatedTenantList);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDisabledButtonIcons(false); // Re-enable button/icons after the update is complete
    }
  };
  return (
    <Fragment>
      <div className="banner-section customise-furniture-back">
        <img src={FurnitureBackImg} alt="assets" />
      </div>

      <div className="cart-sec">
        <div className="container-fluid my-container">
          {finalCartData ? (
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <h4>
                  Your selection ({" "}
                  {finalCartData.furniture_total_price === 0 ? "2" : "3"} items
                  )
                </h4>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <ul>
                          <li>
                            <img
                              src={
                                finalCartData.houseImage
                                  ? import.meta.env.VITE_APP_BASE_URL +
                                    finalCartData?.houseImage
                                  : Cartplan1
                              }
                              alt=""
                            />
                            {finalCartData.house_name}
                          </li>
                          <li>
                            ${finalCartData.house_price}/per month
                            {/* <span>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M18.8484 9.14001L18.1984 19.21C18.0884 20.78 17.9984 22 15.2084 22H8.78844C5.99844 22 5.90844 20.78 5.79844 19.21L5.14844 9.14001"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.3281 16.5H13.6581"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.5 12.5H14.5"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span> */}
                          </li>
                        </ul>
                      </button>
                    </h2>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <ul>
                          <li>
                            <img src={Cartplan1} alt="assets" /> Cleaning Plan
                            Details
                          </li>
                          <li>
                            ${finalCartData.cleaning_total_price}
                            {/* <span>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M18.8484 9.14001L18.1984 19.21C18.0884 20.78 17.9984 22 15.2084 22H8.78844C5.99844 22 5.90844 20.78 5.79844 19.21L5.14844 9.14001"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.3281 16.5H13.6581"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.5 12.5H14.5"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span> */}
                          </li>
                        </ul>
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Cleaning Plan Name</th>
                                <th scope="col">Cleaning Frequency</th>
                                <th scope="col">Price Per Plan</th>
                                <th scope="col">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cartData?.mainCart.cleaningServiceCarts?.map(
                                (cartdata, key) => (
                                  <tr key={key}>
                                    <td>
                                      <img src={Cartplan1} alt="assets" />{" "}
                                      {cartdata.cleaning_service_data.name}
                                    </td>
                                    <td>{cartdata.frequency}</td>
                                    <td>
                                      ${cartdata.cleaning_service_data.price}
                                      /per plan
                                    </td>
                                    <td>
                                      $
                                      {cartdata.frequency *
                                        cartdata.cleaning_service_data.price}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {finalCartData.furniture_total_price > 0 && (
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <ul>
                            <li>
                              <img src={Cart3Img} alt="assets" />{" "}
                              {finalCartData?.furniture_name[0] ||
                                "Furniture Plan Details"}
                            </li>
                            <li>
                              ${finalCartData?.furniture_total_price}/per month
                              {/* <span>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M18.8484 9.14001L18.1984 19.21C18.0884 20.78 17.9984 22 15.2084 22H8.78844C5.99844 22 5.90844 20.78 5.79844 19.21L5.14844 9.14001"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.3281 16.5H13.6581"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.5 12.5H14.5"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span> */}
                            </li>
                          </ul>
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Furniture Package Name</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Price Monthly</th>
                                  <th scope="col">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {cartData?.mainCart.furnitureCarts?.map(
                                  (cartdata, key) => (
                                    <tr key={key}>
                                      <td>
                                        <img
                                          src={
                                            import.meta.env.VITE_APP_BASE_URL +
                                            cartdata.furniture_data
                                              .furniture_images[0].imageUrl
                                          }
                                          alt="assets"
                                        />{" "}
                                        {cartdata.furniture_data.name}
                                      </td>
                                      <td>{cartdata.quantity}</td>
                                      <td>
                                        $ {cartdata.furniture_data.price}/per
                                        month
                                      </td>
                                      <td>
                                        $
                                        {cartdata.quantity *
                                          cartdata.furniture_data.price}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {user?.currentRole === "relo" &&
                    !finalCartData?.parent_cart_id && (
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse3"
                            aria-expanded="false"
                            aria-controls="collapse3"
                          >
                            <ul>
                              <li>
                                <img src={Cartplan1} alt="assets" /> Tenant
                                Details
                              </li>
                            </ul>
                          </button>
                        </h2>
                        <div
                          id="collapse3"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          {tenantList?.length > 0 || inputTenant ? (
                            <>
                              <div className="accordion-body">
                                <div className="table-responsive">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Age</th>
                                        <th scope="col">Gender</th>
                                        <th scope="col">Contact</th>
                                        <th scope="col">Document</th>
                                        <th scope="col">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {tenantList.map((tenantData, key) => (
                                        <tr key={key}>
                                          <td>{key + 1}</td>
                                          <td>
                                            {isEditing === key ? (
                                              <>
                                                <input
                                                  type="text"
                                                  className="tenant-input-list form-control"
                                                  required={true}
                                                  value={editedTenant?.name}
                                                  onChange={(e) =>
                                                    setEditedTenant({
                                                      ...editedTenant,
                                                      name: e.target.value,
                                                    })
                                                  }
                                                />
                                                {error && (
                                                  <p style={{ color: "red" }}>
                                                    {error}
                                                  </p>
                                                )}
                                              </>
                                            ) : (
                                              tenantData.name
                                            )}
                                          </td>
                                          <td>
                                            {isEditing === key ? (
                                              <input
                                                type="number"
                                                className="tenant-input-list form-control"
                                                value={editedTenant.age}
                                                onChange={(e) =>
                                                  setEditedTenant({
                                                    ...editedTenant,
                                                    age: e.target.value,
                                                  })
                                                }
                                              />
                                            ) : (
                                              tenantData.age
                                            )}
                                          </td>
                                          <td>
                                            {isEditing === key ? (
                                              <select
                                                value={editedTenant.gender}
                                                className="tenant-input-list form-control"
                                                onChange={(e) =>
                                                  setEditedTenant({
                                                    ...editedTenant,
                                                    gender: e.target.value,
                                                  })
                                                }
                                              >
                                                <option value="male">
                                                  Male
                                                </option>
                                                <option value="female">
                                                  Female
                                                </option>
                                                <option value="other">
                                                  Other
                                                </option>
                                              </select>
                                            ) : (
                                              tenantData.gender
                                            )}
                                          </td>
                                          <td>
                                            {isEditing === key ? (
                                              <input
                                                type="text"
                                                className="tenant-input-list form-control"
                                                value={
                                                  editedTenant.contact_info
                                                }
                                                onChange={(e) =>
                                                  setEditedTenant({
                                                    ...editedTenant,
                                                    contact_info:
                                                      e.target.value,
                                                  })
                                                }
                                              />
                                            ) : (
                                              tenantData.contact_info
                                            )}
                                          </td>
                                          <td>
                                            <div className="Neon Neon-theme-dragdropbox">
                                              <input
                                                name="files[]"
                                                id="filer_input2"
                                                accept=".jpg, .png, .jpeg,.pdf,.docx"
                                                type="file"
                                                disabled={
                                                  isEditing !== key ||
                                                  !!tenantData.document
                                                }
                                                onChange={handleFileChange}
                                              />
                                              <div className="AddTenant-input-dragDrop">
                                                <div className="Neon-input-inner">
                                                  <div className="Neon-input-icon">
                                                    <img
                                                      src={
                                                        tenantData?.document
                                                          ? `${
                                                              import.meta.env
                                                                .VITE_APP_BASE_URL
                                                            }/${
                                                              tenantData?.document
                                                            }`
                                                          : uploadImage
                                                      }
                                                      alt="upload"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            {isEditing === key ? (
                                              <>
                                                {isDisabledButtonIcons ? (
                                                  <div>
                                                    <Spinner />
                                                  </div>
                                                ) : (
                                                  <button
                                                    className="proceed-btn"
                                                    disabled={
                                                      isDisabledButtonIcons
                                                    }
                                                    onClick={() =>
                                                      handleUpdateTenant(key)
                                                    }
                                                  >
                                                    Update
                                                  </button>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <button
                                                  className="tenantadd-edit-button"
                                                  onClick={() =>
                                                    handleEditTenant(key)
                                                  }
                                                >
                                                  <img
                                                    src={editImage}
                                                    alt="Edit"
                                                  />
                                                </button>
                                                <button
                                                  className="tenantadd-delete-button"
                                                  onClick={() =>
                                                    handleDeleteTenant(key)
                                                  }
                                                >
                                                  <img
                                                    src={deleteImage}
                                                    alt="Delete"
                                                  />
                                                </button>
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                      {tenantList.length < 5 &&
                                        !isEditing &&
                                        inputTenant && (
                                          <tr>
                                            <td>{tenantList.length + 1}</td>
                                            <td>
                                              <input
                                                type="text"
                                                className="tenant-input-list form-control"
                                                placeholder="Enter First and last Name"
                                                value={newTenant.name}
                                                onChange={(e) =>
                                                  setNewTenant({
                                                    ...newTenant,
                                                    name: e.target.value,
                                                  })
                                                }
                                              />
                                              {error && (
                                                <p style={{ color: "red" }}>
                                                  {error}
                                                </p>
                                              )}
                                            </td>
                                            <td>
                                              <input
                                                type="number"
                                                min={0}
                                                max={110}
                                                className="tenant-input-list form-control"
                                                placeholder="Enter Age"
                                                value={newTenant.age}
                                                onChange={(e) =>
                                                  setNewTenant({
                                                    ...newTenant,
                                                    age: e.target.value,
                                                  })
                                                }
                                              />
                                            </td>
                                            <td>
                                              <select
                                                className="form-control"
                                                value={newTenant.gender}
                                                onChange={(e) =>
                                                  setNewTenant({
                                                    ...newTenant,
                                                    gender: e.target.value,
                                                  })
                                                }
                                              >
                                                <option value="">
                                                  Select Gender
                                                </option>
                                                <option value="male">
                                                  Male
                                                </option>
                                                <option value="female">
                                                  Female
                                                </option>
                                                <option value="other">
                                                  Other
                                                </option>
                                              </select>
                                            </td>
                                            <td>
                                              <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Enter any Contact"
                                                value={newTenant?.contact_info}
                                                onChange={(e) =>
                                                  setNewTenant({
                                                    ...newTenant,
                                                    contact_info:
                                                      e.target.value,
                                                  })
                                                }
                                              />
                                            </td>
                                            <td>
                                              <div className="Neon Neon-theme-dragdropbox ">
                                                <input
                                                  className="tenant-input-list-upload"
                                                  name="files[]"
                                                  id="filer_input2"
                                                  accept=".jpg, .png, .jpeg"
                                                  type="file"
                                                  onChange={handleFileChange}
                                                />
                                                <div className="AddTenant-input-dragDrop">
                                                  <div className="Neon-input-inner">
                                                    <div className="Neon-input-icon">
                                                      <img
                                                        src={
                                                          urlData || uploadImage
                                                        }
                                                        alt="edit"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              {isDisabledButtonIcons ? (
                                                <div>
                                                  {" "}
                                                  <Spinner />
                                                </div> // This is your loading spinner
                                              ) : (
                                                <button
                                                  className="add-tenant-button"
                                                  disabled={
                                                    isDisabledButtonIcons
                                                  }
                                                  onClick={() =>
                                                    handleAddTenant()
                                                  }
                                                >
                                                  <img src={addUserImage}></img>
                                                  {isDisabledButtonIcons}
                                                </button>
                                              )}
                                            </td>
                                          </tr>
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          ) : (
                            <p>No tenants available.</p>
                          )}

                          {tenantList.length < 4 &&
                            isEditing !== 0 &&
                            isEditing === null && (
                              <div>
                                <button
                                  className="proceed-btn btn-primary-add-tenant"
                                  disabled={isDisabledButtonIcons}
                                  onClick={() => {
                                    setIsEditing(null);
                                    setInputTenant((prevState) => !prevState); // Toggle form visibility
                                  }}
                                >
                                  Add Tenant
                                </button>
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <h4>Cart Total</h4>
                <div className="sub-total-new">
                  <ul>
                    <li>
                      Sub total ( monthly) :{" "}
                      <span>${finalCartData?.subTotal}</span>
                    </li>
                    <li>
                      Security Deposit (Non-refundable) :{" "}
                      <span>${finalCartData?.security_price}</span>
                    </li>
                    <li>
                      Total Amount : ( {noOfMonths}{" "}
                      {noOfMonths === 1 ? "Month" : "Months"}{" "}
                      {noOfRemaingDays > 0 &&
                        `and ${noOfRemaingDays} Day${
                          noOfRemaingDays !== 1 ? "s" : ""
                        }`}{" "}
                      ) <span>${totalAmount}</span>
                    </li>
                  </ul>

                  {isChecked && (
                    <>
                      <div className="description-heading">
                        Monthly Rent Amount
                      </div>

                      <ul className="month-rent">
                        {monthlypay.map((element, i) => (
                          <li key={i}>
                            {element.month}
                            <span>${element.amount}</span>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  <div className="description-heading">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={
                          !isChecked ||
                          (noOfMonths === 1 && noOfRemaingDays === 0)
                        } // Checked by default and checked when condition is met
                        disabled={noOfMonths === 1 && noOfRemaingDays === 0}
                        onChange={handleCheckboxChange}
                        id="flexCheckDefault3"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault3"
                      >
                        Pay Full Amount
                      </label>
                    </div>
                  </div>
                  <button
                    className="proceed-btn  d-flex align-items-center justify-content-center"
                    onClick={handleOpenModal}
                    disabled={
                      isDisabledButton ||
                      (user?.currentRole === "relo" &&
                        !tenantList.length &&
                        !finalCartData?.parent_cart_id)
                    }
                  >
                    {isDisabledButton ? (
                      <Loader />
                    ) : (
                      `Proceed to Checkout: ${parseFloat(proceedAmount).toFixed(
                        2
                      )}`
                    )}
                  </button>
                  {user?.currentRole === "relo" &&
                    tenantList.length === 0 &&
                    !finalCartData?.parent_cart_id && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Before Payment, Please Add Tenant
                      </div>
                    )}

                  <ModalComponent
                    showModal={showModal}
                    setShowModal={setShowModal}
                    handleCloseModal={handleCloseModal}
                    cardData={cardData}
                    FetchCardData={FetchData}
                    totalAmount={parseFloat(proceedAmount).toFixed(2)}
                    cartId={finalCartData?.cartId}
                    subscription_type={subscriptionType}
                    housePrice={finalCartData?.house_price}
                    setIsDisabledButton={setIsDisabledButton}
                  ></ModalComponent>
                </div>
              </div>
            </div>
          ) : (
            // <div className="d-flex justify-content-center">
            //   <Spinner />
            // </div>

            <div className="eye-loader-frame m-5 p-5">
              <div className="eye-loader-spinner"></div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default AllDataInCart;
