import { Fragment } from "react";
import { useState } from "react";
import { Navbar } from "react-bootstrap";
import { Link, useNavigate, NavLink } from "react-router-dom";
import LogoSvg from "../../../assets/images/logo.svg";
import SignInSvg from "../../../assets/images/signin.svg";
import Bell from "../../../assets/images/Bell.svg";

import { useUserState } from "../../../Contexts/UserContext";
import { TimeCountDown } from "../../TimeCountDown";
import "./HeaderStyle.css";

const Header = () => {
  const { user, isLoggedIn, unreadNotificationCount } = useUserState();

  return (
    <Fragment>
      <header className="top-header">
        <Navbar className="navbar navbar-expand-lg">
          <div className="container my-container">
            <Link to={`/`}>
              <img src={LogoSvg} />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <Navbar.Collapse id="basic-navbar-nav">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  {isLoggedIn ? (
                    <>
                      <li className="nav-item   custom-menu timer-sec-menu">
                        <Link
                          className="nav-link"
                          to={`/user_dash/${user?.currentRole}/notifications`}
                        >
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <div className="sb-nav-link-icon sb-nav-link-icon-bell">
                              {/* <i className="fa-solid fa-bell"></i> */}
                              <img src={Bell} alt="notifications" />
                            </div>
                            {unreadNotificationCount > 0 && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0px",
                                  right: "0px",
                                  backgroundColor: "red",
                                  borderRadius: "50%",
                                  width: "20px",
                                  height: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                {unreadNotificationCount}
                              </div>
                            )}
                          </div>
                          <div>
                            {[("tenant", "relo")].includes(
                              user?.currentRole
                            ) && <TimeCountDown />}
                          </div>
                        </Link>
                      </li>
                    </>
                  ) : null}
                  {!user ? (
                    <li className="nav-item">
                      <NavLink className="nav-link" to={`/`}>
                        View properties
                      </NavLink>
                    </li>
                  ) : (
                    [("tenant", "relo")].includes(user?.currentRole) && (
                      <li className="nav-item">
                        <NavLink className="nav-link" to={`/`}>
                          View properties
                        </NavLink>
                      </li>
                    )
                  )}
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/contact-us">
                      Contact Us
                    </NavLink>
                  </li>
                  {!isLoggedIn && (
                    <li className="nav-item ">
                      <Link to={`/login`}>
                        <button className="signIn-btn" href="#">
                          <img src={SignInSvg} /> Sign In
                        </button>
                      </Link>
                    </li>
                  )}
                  {isLoggedIn && (
                    <li className="nav-item ">
                      <Link to={`/user_dash/${user?.currentRole}`}>
                        {user.first_name} {user.last_name}
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </header>
    </Fragment>
  );
};

export default Header;
