import { useState, Fragment } from "react";

import { Link } from "react-router-dom";

import { useUserState, useUserDispatch } from "../Contexts/UserContext";
import TermConditionModal from "./termsConPrivacyModals/TermConditionModal";
import PrivacyModal from "./termsConPrivacyModals/PrivacyModal";

import logo from "../assets/images/logo.png";
import approveImage from "../assets/images/Approve.png";
import mailImage from "../assets/images/mailmessage.png";
import instaImage from "../assets/images/instagram.png";
import telephoneImage from "../assets/images/telephone.png";
import twitterImage from "../assets/images/twitter.png";

const FooterDash = () => {
  const { user } = useUserState();
  const dispatch = useUserDispatch();
  const [termConModal, setTermConModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);

  const otherRoles = user?.role?.filter((r) => r !== user?.currentRole);

  return (
    <Fragment>
      <footer>
        <section className="foot_sec">
          <div className="container-fluid">
            <div className="foot_sec_inner">
              <div className="foot_cont">
                <div className="foot_logo">
                  <Link to="/">
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <p>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old.
                </p>
              </div>
              <div className="foot_cont">
                <div className="foot_head">Druyp</div>
                <ul>
                  <li>
                    <Link to="/">
                      <img src={approveImage} alt="" /> Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/properties">
                      <img src={approveImage} alt="" /> Get a Choice
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="">
                      <img src={approveImage} alt="" /> Download
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/contact-us">
                      <img src={approveImage} alt="" /> Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              {user &&
                user?.role?.length <= 3 &&
                user?.role?.length >1 &&
                !user?.role?.includes("furniture_company") &&
                !user?.role?.includes("cleaning_company") &&
                !user?.role?.includes("relo") &&
                 (
                  <div className="foot_cont">
                    <div className="foot_head">Host</div>
                    <ul>
                      {otherRoles.map((r, index) => (
                        <li key={index}>
                          <Link
                            to={
                              r === "tenant"
                                ? "/user_dash/tenant"
                                : r === "home_owner"
                                ? "/user_dash/home_owner"
                                : r === "relo"
                                ? "/user_dash/relo"
                                : r === "property_manager"
                                ? "/user_dash/property_manager"
                                : ""
                            }
                            onClick={() => {
                              window.scrollTo({
                                top: 0,
                                behavior: "instant",
                              });
                              const updatedUserRole = {
                                ...user,
                                currentRole: r,
                              };
                              // Dispatch an action to update the user in the context
                              dispatch({
                                type: "UPDATE_USER",
                                payload: updatedUserRole,
                              });
                            }}
                          >
                            <img src={approveImage} />{" "}
                            {r === "tenant"
                              ? "Tenant"
                              : r === "relo"
                              ? "Relocation Agent"
                              : r === "home_owner"
                              ? "Home Owner"
                              : r === "property_manager"
                              ? "Property Manager"
                              : ""}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              <div className="foot_cont">
                <div className="foot_head">Other Links</div>
                <ul>
                  <li>
                    <Link to="/about-us">
                      <img src={approveImage} alt="" /> About Us
                    </Link>
                  </li>
                  <li>
                    <button onClick={() => setTermConModal(true)}>
                      <img src={approveImage} alt="" /> Terms & Conditions
                    </button>
                  </li>
                  <li>
                    <button onClick={() => setPrivacyModal(true)}>
                      <img src={approveImage} alt="" /> Privacy Policy
                    </button>
                  </li>
                </ul>
              </div>
              <div className="foot_cont">
                <div className="foot_head">Contact us</div>
                <ul>
                  <li>
                    <a href="#">
                      <img src={telephoneImage} alt="" /> (217) 443-4545
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={mailImage} alt="" /> info@druyp.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={instaImage} alt="" /> @druyp_insta
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <img src={twitterImage} alt="" /> @druyp_twitter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="m-0" />
          <div className="foot_copyright">
            <p>© All rights reserved. Druyp</p>
          </div>
        </section>
      </footer>
      <TermConditionModal
        termConModal={termConModal}
        setTermConModal={setTermConModal}
      />
      <PrivacyModal
        privacyModal={privacyModal}
        setPrivacyModal={setPrivacyModal}
      />
    </Fragment>
  );
};

export default FooterDash;
