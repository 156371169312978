import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useFetcher } from "react-router-dom";
import io from "socket.io-client";

import Login from "../components/Login";
import Forgot_password from "../components/Forgot_password";
import Signup from "../components/Signup";
import Otp_Screen from "../components/Otp_Screen";
import Change_password from "../components/change_password";
import UserDash from "../components/UserDash";
import UserHistory from "../components/UserHistory";
import UserProfile from "../components/UserProfile";
import UserBilling from "../components/UserBilling";
import UserEdit from "../components/UserEdit";
import OwnerHouse from "../components/OwnerHouses";
import OwnerAddHouse from "../components/OwnerAddHouse";
import FavoriteHouses from "../components/FavoriteHouses";
import { HouseContext } from "../Contexts/HouseContext";
import LoginSecurity from "../components/LoginSecurity";
import { ProtectedRoute } from "../components/common/ProtectedRoute";
import axios from "axios";

// tenant
import CurrentPlan from "@tenant/currentPlan";
// common
import Notifications from "@common/Notifications";
import MyBillingDetails from "@furniture/myBilling";
// furniture files
import MyHistory from "@furniture/myHistory";
import MyFurnitureStore from "@furniture/myFurnitureStore";
import MyBookingDetails from "@furniture/myBooking";
import AddNewFurniture from "@furniture/addNewFurniture";
// cleaning components
import MyCleaningStore from "@cleaning/MyCleaningStore";
import CleaningHistory from "@cleaning/CleaningHistory";
import MyCleaningBooking from "@cleaning/MyCleaningBooking";
import AddCleaningService from "@cleaning/AddCleaningService";
import VerifyEmail from "@common/VerifyEmail";
import Home from "../components/home/Home";
import PropertyDetail from "@components/property/propertyDetail";
import FurniturePackage from "@components/furnitureFront/FurniturePackage";
import Furniture from "@components/furnitureFront/Furniture";
import CustomiseFurniture from "@components/furnitureFront/customise/CustomiseFurniture";
import Layout from "../components/homeOutlet/Layout";
import PropertyListing from "../components/property/propertyListing/index";
import CleaningUserSideScreen from "../components/cleaning/cleaning-customer-side/index";
import AllDataInCart from "../components/cart/index";
import Negotiations from "../components/common/Negotiations";
import ThankYouPage from "../components/common/ThanksPage";
import CleaningPlan from "../components/cleaning/cleaning-customer-side/cleaning-plan";
import PrivateRoute from "../components/PrivateRoute";
import ContactUs from "../pages/ContactUs";
import AboutUs from "../pages/AboutUs";
import CleaningPackageList from "../pages/CleaningPackageList";

import {
  useUserState,
  useUserDispatch,
  UserContext,
} from "../Contexts/UserContext";
import { NotificationContext } from "../Contexts/NotificationContext";
import { TimeCountDown } from "../components/TimeCountDown";
import { Modal } from "antd";
import { useContext } from "react";

const RouteTable = () => {
  const { user, isLogged } = useUserState();
  const dispatch = useUserDispatch();
  const [house, setHouse] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const [notificationList, setNotificationList] = useState([]);
  const [unreadNotificationList, setUnreadNotificationList] = useState([]);
  const [showComponentA, setShowComponentA] = useState(true);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [message, setMessage] = useState({
    title: "Time Expired",
    description: "Time has expired. Do you want to extend the time?",
    type: "warning",
  });

  // const[negotiationId,setNegotiationId]=useState()
  const toggleComponent = () => {
    setShowComponentA(!showComponentA);
  };

  const { negotiationId, expiryTiming } = useContext(UserContext);

  useEffect(() => {
    if (expiryTiming) {
      setIsModalVisible(false);
    }
  }, [expiryTiming]);

  const [showComponentC, setShowComponentC] = useState(true);
  const toggleComponentHouse = () => {
    setShowComponentC(!showComponentC);
  };

  const getNotifications = async () => {
    setIsLoader(true);
    try {
      const headers = {
        Authorization: user?.token,
      };
      const response = await axios.get(
        `${import.meta.env.VITE_APP_BASE_URL}/api/notification/get-by-user`,
        {
          headers: headers,
        }
      );
      setIsLoader(false);
      setNotificationList(response.data.data);
      setUnreadNotificationList(
        notificationList.filter((notification) => !notification.is_read)
      );
      // const currentTime = new Date();
      // const filteredData = response.data.data.filter((item) => {
      //   const createdAtTime = new Date(item.createdAt);
      //   const timeDifferenceInSeconds = (currentTime - createdAtTime) / 1000;
      //   return (
      //     (timeDifferenceInSeconds <= 45 && item.is_active) ||
      //     (timeDifferenceInSeconds <= 45 && item.count === 5)
      //   );
      // });

      // // dispatch({ type: "LOGIN", payload: user });

      // filteredData.forEach((item) => {
      //   addNotification({
      //     title: "Druyp",
      //     subtitle: "Notification",
      //     message: item.message,
      //     theme: "darkblue",
      //     native: true,
      //   });
      // });
    } catch (err) {
      console.log("err", err);
      setNotificationList([]);
    }
  };
  useEffect(() => {
    dispatch({
      type: "UPDATE_NOTIFICATION_COUNT",
      payload: unreadNotificationList.length,
    });
  }, [unreadNotificationList]);
  useEffect(() => {
    // Check if the browser supports the Notifications API
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else {
      // Check the current permission status
      if (Notification.permission === "granted") {
        // Permission is already granted
        console.log("Notification permission is granted");
      } else if (Notification.permission !== "denied") {
        // Request permission from the user
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Notification permission is granted");
          } else {
            console.log("Notification permission is denied");
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (user) {
      getNotifications();
      const socket = io(`${import.meta.env.VITE_APP_BASE_URL}`);

      socket.on("connect", () => {
        const userId = user?.email; // Replace with actual user ID
        socket.emit("addUser", userId);
      });

      socket.on("notification", (notificationData) => {
        // console.log("noti- realtime", notificationData);
        let allNotifications = [notificationData, ...notificationList];
        setNotificationList((prev) => [notificationData, ...prev]);
        // console.log(
        //   allNotifications?.filter((notification) => !notification?.is_read)
        //     ?.length
        // );
        dispatch({
          type: "UPDATE_NOTIFICATION_COUNT",
          payload:
            allNotifications?.filter((notification) => !notification?.is_read)
              ?.length || 0,
        });
        if (!notificationData.is_read) {
          new Notification("Druyp", {
            body: notificationData.message,
          });
        }
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [user]);

  // context for notification

  const handleOk = async () => {
    setIsModalVisible(false);

    const headerJson = {
      "Content-Type": "application/json",
      Authorization: user?.token,
    };

    //   try {
    //     const response = await axios.put(
    //       `${import.meta.env.VITE_APP_BASE_URL}/api/house-price-negotiate/update/request-for-extend-time/${negotiationId}`,
    //         { headers: headerJson }
    //     );

    //     console.log("Response:==", response);
    //     console.log("Yes clicked");
    //   } catch (error) {
    //     console.error("Error updating negotiation:", error);
    //   }
    // };
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${
        import.meta.env.VITE_APP_BASE_URL
      }/api/house-price-negotiate/update/request-for-extend-time/${negotiationId}`,
      // }/api/house-price-negotiate/update/request-for-extend-time/709`,
      headers: {
        Authorization: user?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        if (error.response.status === 403) {
          setMessage({
            title: "Limit  exceeded",
            description: error.response.data.message,
            type: "error",
          });
          setIsModalVisible(true);
        }
      });
  };
  const handleErrorModel = () => {
    setIsModalVisible(false);

    window.location.href = "/";
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        import.meta.env.VITE_APP_BASE_URL
      }/api/house-price-negotiate/expire/tenant/${negotiationId}`,
      headers: {
        Authorization: user?.token,
      },
    };

    axios
      .request(config)
      .then(() => {
        window.location.href = "/";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <BrowserRouter>
        <NotificationContext.Provider
          value={{
            notificationList,
            setNotificationList,
            isModalVisible,
            setIsModalVisible,
          }}
        >
          <Routes>
            <Route
              path="/login"
              element={
                <PrivateRoute>
                  <Login />{" "}
                </PrivateRoute>
              }
            />
            {/* <Route path="/" element={<Login />} /> */}
            <Route element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/properties" element={<PropertyListing />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/about-us" element={<AboutUs />} />
            </Route>

            <Route
              path="/cleaning/package"
              element={<CleaningUserSideScreen />}
            />

            {/*<Routes>
            <Route path="/property_detail/:houseId" element={<Layout />}>
              <Route index element={<PropertyDetail />} />
            </Route>
          </Routes>*/}

            <Route element={<Layout />}>
              <Route
                path="/property_detail/:houseId"
                element={<PropertyDetail />}
              />
              <Route path="/furniture_package" element={<FurniturePackage />} />
              <Route
                path="/cleaning-package"
                element={<CleaningPackageList />}
              />
              <Route path="/furniture" element={<Furniture />} />
              <Route
                path="/customise-furniture"
                element={<CustomiseFurniture />}
              />
              <Route
                path="/cleaning/package"
                element={<CleaningUserSideScreen />}
              />
              <Route path="/cleaning/company" element={<CleaningPlan />} />
              <Route path="/cart" element={<AllDataInCart />} />
              <Route path="/thanks" element={<ThankYouPage />} />
            </Route>

            {/* tenant routes */}
            <Route
              path="/user_dash/tenant"
              element={
                // <ProtectedRoute>
                <UserDash />
                // </ProtectedRoute>
              }
            >
              <Route
                path="/user_dash/tenant"
                element={
                  showComponentA ? (
                    <UserProfile toggleComponent={toggleComponent} />
                  ) : (
                    <UserEdit toggleComponent={toggleComponent} />
                  )
                }
              />
              <Route
                path="/user_dash/tenant/history"
                element={<UserHistory />}
              />
              <Route
                path="/user_dash/tenant/billing"
                element={<MyBillingDetails />}
              />
              <Route
                path="/user_dash/tenant/login_security"
                element={<LoginSecurity />}
              />
              <Route
                path="/user_dash/tenant/notifications"
                element={
                  <Notifications
                    isLoader={isLoader}
                    setIsLoader={setIsLoader}
                    notificationList={notificationList}
                    setNotificationList={setNotificationList}
                    getNotifications={getNotifications}
                  />
                }
              />
              <Route
                path="/user_dash/tenant/current-plan"
                element={<CurrentPlan />}
              />
              <Route
                path="/user_dash/tenant/favorite-houses"
                element={<FavoriteHouses />}
              />
              <Route
                path="/user_dash/tenant/negotiations"
                element={<Negotiations />}
              />
            </Route>

            {/* relo routes */}
            <Route
              path="/user_dash/relo"
              element={
                // <ProtectedRoute>
                <UserDash />
                // </ProtectedRoute>
              }
            >
              <Route
                path="/user_dash/relo"
                element={
                  showComponentA ? (
                    <UserProfile toggleComponent={toggleComponent} />
                  ) : (
                    <UserEdit toggleComponent={toggleComponent} />
                  )
                }
              />
              <Route
                path="/user_dash/relo/history"
                element={<UserHistory />}
              />
              <Route
                path="/user_dash/relo/billing"
                element={<MyBillingDetails />}
              />
              <Route
                path="/user_dash/relo/login_security"
                element={<LoginSecurity />}
              />
              <Route
                path="/user_dash/relo/notifications"
                element={
                  <Notifications
                    isLoader={isLoader}
                    setIsLoader={setIsLoader}
                    notificationList={notificationList}
                    setNotificationList={setNotificationList}
                    getNotifications={getNotifications}
                  />
                }
              />
              <Route
                path="/user_dash/relo/current-plan"
                element={<CurrentPlan />}
              />
              <Route
                path="/user_dash/relo/favorite-houses"
                element={<FavoriteHouses />}
              />
              <Route
                path="/user_dash/relo/negotiations"
                element={<Negotiations />}
              />
            </Route>

            {/* home owner routes */}
            <Route path="/user_dash/home_owner" element={<UserDash />}>
              <Route
                path="/user_dash/home_owner"
                element={
                  showComponentA ? (
                    <UserProfile toggleComponent={toggleComponent} />
                  ) : (
                    <UserEdit toggleComponent={toggleComponent} />
                  )
                }
              />
              <Route
                path="/user_dash/home_owner/notifications"
                element={
                  <Notifications
                    isLoader={isLoader}
                    setIsLoader={setIsLoader}
                    notificationList={notificationList}
                    setNotificationList={setNotificationList}
                    getNotifications={getNotifications}
                  />
                }
              />
              <Route
                path="/user_dash/home_owner/login_security"
                element={<LoginSecurity />}
              />
              <Route
                path="/user_dash/home_owner/negotiations"
                element={<Negotiations />}
              />
              <Route
                path="/user_dash/home_owner/history"
                element={<UserHistory />}
              />
              <Route
                path="/user_dash/home_owner/billing"
                element={<MyBillingDetails />}
              />
              <Route
                path="/user_dash/home_owner/my_houses"
                element={
                  showComponentC ? (
                    <HouseContext.Provider value={{ house, setHouse }}>
                      <OwnerHouse toggleComponentHouse={toggleComponentHouse} />
                    </HouseContext.Provider>
                  ) : (
                    <HouseContext.Provider value={{ house, setHouse }}>
                      <OwnerAddHouse
                        toggleComponentHouse={toggleComponentHouse}
                      />
                    </HouseContext.Provider>
                  )
                }
              />
            </Route>

            {/* cleaning routes */}
            <Route path="/user_dash/cleaning_company" element={<UserDash />}>
              <Route
                path="/user_dash/cleaning_company"
                element={
                  showComponentA ? (
                    <UserProfile toggleComponent={toggleComponent} />
                  ) : (
                    <UserEdit toggleComponent={toggleComponent} />
                  )
                }
              />
              <Route
                path="/user_dash/cleaning_company/cleaning_store"
                element={<MyCleaningStore />}
              />
              <Route
                path="/user_dash/cleaning_company/add-cleaning-service"
                element={<AddCleaningService />}
              />
              <Route
                path="/user_dash/cleaning_company/login_security"
                element={<LoginSecurity />}
              />

              <Route
                path="/user_dash/cleaning_company/add_new_furniture"
                element={<AddNewFurniture />}
              />
              <Route
                path="/user_dash/cleaning_company/booking_details"
                element={<MyCleaningBooking />}
              />
              <Route
                path="/user_dash/cleaning_company/login_security"
                element={<LoginSecurity />}
              />
              <Route
                path="/user_dash/cleaning_company/notifications"
                element={
                  <Notifications
                    isLoader={isLoader}
                    setIsLoader={setIsLoader}
                    notificationList={notificationList}
                    setNotificationList={setNotificationList}
                    getNotifications={getNotifications}
                  />
                }
              />

              <Route
                path="/user_dash/cleaning_company/history"
                element={<CleaningHistory />}
              />
              <Route
                path="/user_dash/cleaning_company/billing"
                element={<MyBillingDetails />}
              />
            </Route>

            {/* furniture routes */}
            <Route path="/user_dash/furniture_company" element={<UserDash />}>
              <Route
                path="/user_dash/furniture_company"
                element={
                  showComponentA ? (
                    <UserProfile toggleComponent={toggleComponent} />
                  ) : (
                    <UserEdit toggleComponent={toggleComponent} />
                  )
                }
              />
              <Route
                path="/user_dash/furniture_company/furniture_store"
                element={<MyFurnitureStore />}
              />
              <Route
                path="/user_dash/furniture_company/login_security"
                element={<LoginSecurity />}
              />

              <Route
                path="/user_dash/furniture_company/add_new_furniture"
                element={<AddNewFurniture />}
              />
              <Route
                path="/user_dash/furniture_company/booking_details"
                element={<MyBookingDetails />}
              />
              <Route
                path="/user_dash/furniture_company/login_security"
                element={<LoginSecurity />}
              />
              <Route
                path="/user_dash/furniture_company/notifications"
                element={
                  <Notifications
                    isLoader={isLoader}
                    setIsLoader={setIsLoader}
                    notificationList={notificationList}
                    setNotificationList={setNotificationList}
                    getNotifications={getNotifications}
                  />
                }
              />

              <Route
                path="/user_dash/furniture_company/history"
                element={<MyHistory />}
              />
              <Route
                path="/user_dash/furniture_company/billing"
                element={<UserBilling />}
              />
            </Route>

            {/* property_manager routes */}
            <Route path="/user_dash/property_manager" element={<UserDash />}>
              <Route
                path="/user_dash/property_manager"
                element={
                  showComponentA ? (
                    <UserProfile toggleComponent={toggleComponent} />
                  ) : (
                    <UserEdit toggleComponent={toggleComponent} />
                  )
                }
              />
              <Route
                path="/user_dash/property_manager/notifications"
                element={
                  <Notifications
                    isLoader={isLoader}
                    setIsLoader={setIsLoader}
                    notificationList={notificationList}
                    setNotificationList={setNotificationList}
                    getNotifications={getNotifications}
                  />
                }
              />
              <Route
                path="/user_dash/property_manager/login_security"
                element={<LoginSecurity />}
              />
              <Route
                path="/user_dash/property_manager/negotiations"
                element={<Negotiations />}
              />
              <Route
                path="/user_dash/property_manager/history"
                element={<UserHistory />}
              />
              <Route
                path="/user_dash/property_manager/billing"
                element={<MyBillingDetails />}
              />
              <Route
                path="/user_dash/property_manager/my_houses"
                element={
                  showComponentC ? (
                    <HouseContext.Provider value={{ house, setHouse }}>
                      <OwnerHouse toggleComponentHouse={toggleComponentHouse} />
                    </HouseContext.Provider>
                  ) : (
                    <HouseContext.Provider value={{ house, setHouse }}>
                      <OwnerAddHouse
                        toggleComponentHouse={toggleComponentHouse}
                      />
                    </HouseContext.Provider>
                  )
                }
              />
            </Route>
            <Route path="/password_reset" element={<Forgot_password />} />
            <Route path="/sign_up" element={<Signup />} />
            <Route path="/otp" element={<Otp_Screen />} />
            <Route path="/change_Password" element={<Change_password />} />
            <Route path="/verify_email" element={<VerifyEmail />} />
          </Routes>
          <Modal
            title={message.title}
            open={isModalVisible}
            onOk={message.type === "warning" ? handleOk : handleErrorModel}
            okText={message.type === "warning" ? "Yes" : "Ok"}
            cancelText={message.type === "warning" ? "Cancel" : undefined}
            cancelButtonProps={
              message.type === "warning"
                ? undefined
                : { style: { display: "none" } }
            }
            onCancel={handleCancel}
          >
            <p>{message.description}</p>
          </Modal>
        </NotificationContext.Provider>
      </BrowserRouter>
    </div>
  );
};
export default RouteTable;
